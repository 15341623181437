
const state = {
  userdata: null, 
  activeToggle: false, 
  permission: [
    "CALENDAR",
    // appointment
    "VIEW_APPOINTMENT",
    "CREATE_APPOINTMENT",
    "CHECKOUT_APPOINTMENT",
    "SET_DISCOUNT_APPOINTMENT",
    "CHANGE_STATUS_APPOINTMENT",
    "CHANGE_PAYMENT_STATUS_APPOINTMENT",
    "REFUND_APPOINTMENT",
    "PRINT_RECEIPT_APPOINTMENT",
    "EXPORT_DATA_APPOINTMENT",
    // user
    "VIEW_USER",
    "CREATE_USER",
    "IMPORT_USER",
    "UPDATE_USER",
    "DELETE_USER",
    "CHECKOUT_PACKAGE_USER",
    "SET_DISCOUNT_CHECKOUT_PACKAGE_USER",
    "HISTORY_APPOINTMENT_USER",
    "HISTORY_PURCHASE_USER",
    "PROGRESS_NOTE_USER",
    // category
    "VIEW_CATEGORY",
    "CREATE_CATEGORY",
    "UPDATE_CATEGORY",
    "DELETE_CATEGORY",
    // treatment
    "VIEW_TREATMENT",
    "CREATE_TREATMENT",
    "UPDATE_TREATMENT",
    "DELETE_TREATMENT",
    // location
    "VIEW_LOCATION",
    "CREATE_LOCATION",
    "UPDATE_LOCATION",
    "DELETE_LOCATION",
    // room
    "VIEW_ROOM",
    "CREATE_ROOM",
    "UPDATE_ROOM",
    "DELETE_ROOM",
    // employee
    "VIEW_EMPLOYEE",
    "CREATE_EMPLOYEE",
    "UPDATE_EMPLOYEE",
    "DELETE_EMPLOYEE",
    // package
    "VIEW_PACKAGE",
    "CREATE_PACKAGE",
    "UPDATE_PACKAGE",
    "DELETE_PACKAGE",
    // equipment
    "VIEW_EQUIPMENT",
    "CREATE_EQUIPMENT",
    "UPDATE_EQUIPMENT",
    "DELETE_EQUIPMENT",
    // role
    "VIEW_ROLE",
    "CREATE_ROLE",
    "UPDATE_ROLE",
    "PERMISSION_ROLE",
    "DELETE_ROLE",
    // staff
    "VIEW_STAFF",
    "CREATE_STAFF",
    "UPDATE_STAFF",
    "DELETE_STAFF",
    // checkout product
    "VIEW_CHECKOUT_PRODUCT",
    "ADD_CHECKOUT_PRODUCT",
    "PRINT_RECEIPT_CHECKOUT_PRODUCT",
    // voucher
    "VIEW_VOUCHER",
    "CREATE_VOUCHER",
    "UPDATE_VOUCHER",
    "DELETE_VOUCHER",
  ]
}

export default state
