import Vue from 'vue'
import VueRouter from 'vue-router' 
import VueMeta from 'vue-meta'

import store from '@/state/store' 
Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
    routes: [
   
    {
        path: '/',
        name: 'login',
        component: () => import('./views/account/login'),
        meta: {
            guest: true
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./views/account/forgot-password'),
        meta: {
            guest: true
        }
    }, 
    {
        path: '/reset-password/:token',
        name: 'Reset Password',
        component: () => import('./views/reset-password/reset-password'),
        meta: {
            guest: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('./views/profile/form'),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/home', 
        name: 'home',
        component: () => import('./views/dashboard/home'),
        meta: { 
            authRequired: true 
        },
    },
    {
        path: '/about', 
        name: 'about',
        component: () => import('./views/about/form'),
        meta: { 
            authRequired: true 
        },
    },
    {
        path: '/contact', 
        name: 'contact',
        component: () => import('./views/contact/form'),
        meta: { 
            authRequired: true 
        },
    },
    {
        path: '/banner',
        name: 'banner',
        component: () => import('./views/banner/list-banner'),
        meta: {
            authRequired: true
        },

    },
    {
        path: '/banner/create',
        name: 'banner-create',
        component: () => import('./views/banner/form-create'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/banner/edit/:id',
        name: 'banner-edit',
        component: () => import('./views/banner/form-edit'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/social-media',
        name: 'social-media',
        component: () => import('./views/sosmed/form'),
        meta: {
            authRequired: true
        },

    },
    {
        path: '/region',
        name: 'region',
        component: () => import('./views/region/list-region'),
        meta: {
            authRequired: true
        },

    },
    {
        path: '/region/create',
        name: 'region-create',
        component: () => import('./views/region/form-create'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/region/edit/:id',
        name: 'region-edit',
        component: () => import('./views/region/form-edit'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/committee',
        name: 'committee',
        component: () => import('./views/committee/list-committee'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/committee/create',
        name: 'committee-crete',
        component: () => import('./views/committee/form-create.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/committee/edit/:id',
        name: 'committee-edit',
        component: () => import('./views/committee/form-edit.vue'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/category',
        name: 'category',
        component: () => import('./views/category/list-category'),
        meta: {
            authRequired: true
        },

    },
    {
        path: '/category/create',
        name: 'category-create',
        component: () => import('./views/category/form-create'),
        meta: {
            authRequired: true
        },
    },
    {
        path: '/category/edit/:id',
        name: 'category-edit',
        component: () => import('./views/category/form-edit'),
        meta: {
            authRequired: true
        },
    },
    // guest
    {
        path: '/pages/404',
        name: 'Page-404',
        component: () => import('./views/pages/error-404'),
        meta: {
            guest: true,
        }
    },
    {
        path: '/pages/500',
        name: 'Page-500',
        component: () => import('./views/pages/error-500'),
        meta: {
            guest: true,
        }
    },
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        component: () => import('./views/pages/maintenance'),
        meta: {
            guest: true,
        }
    },
    // Redirect to 404 page, if no match found
    {
        path: '*',
        redirect: '/pages/error-404'
    }
]
});
// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.authRequired)) {
//       if (store.state.userdata == null) {
//         next('/')
//       }
//     }
//     if (to.matched.some(record => record.meta.guest)) {
//       // this route check if user have logged in redirect to home page
//       if (store.state.userdata !== null) {
//         next('/home')
//       }
//     }
//     next()
// });
export default router