const getters = {
    myPermission: (state) => {
        if (state.userdata.role.name == 'admin') {
            return state.permission
        } else {
            return state.userdata.role.permissions
        }
    },
    checkPermission: (state, getters) => (permission) => {
        return getters.myPermission.includes(permission);
    },
}

export default getters
